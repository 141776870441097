import React from "react"
import { navigate } from "gatsby"
import { Row, Col } from "antd"
import "antd/lib/grid/style/index.css"
import "tingle.js/dist/tingle.css"
export const windowGlobal = typeof window !== 'undefined' && window

class TripleContentColumns extends React.Component {
  openModal = (content) => {
    const tingle = require("tingle.js")
    windowGlobal.navigate = navigate
    windowGlobal.whyIndModal = new tingle.modal({})
    windowGlobal.whyIndModal.setContent(content)
    windowGlobal.whyIndModal.open()
  }

  render() {

    return <div className="features-extended-inner">
      <Row gutter={24} className="feature-extended content-columns" type={"flex"}>
        {
          this.props.columns.map((col, index) => {
            return <Col md={8} key={index}
                        className={col.list_content ? "feature-extended-body checkmark" : "feature-extended-image"}
                        style={{
                          paddingTop: "60px"
                        }}>
              {col.list_content && <div>
                <div dangerouslySetInnerHTML={{ __html: col.list_content }}/>
                <p
                  style={{
                    position: "absolute",
                    bottom: -60,
                    textAlign: "center",
                    left:0,
                    right: 0
                  }}
                  className="hero-cta">
                  <a
                    className="button button-primary cta-shadow"
                    onClick={() => this.openModal(col.modal_content)}>
                    Learn More
                  </a>
                </p>

              </div>}

              {(col.images && col.images.length) ? <div>
                <img src={col.images[0]} className="svg-overlay"/>
                <img src={col.images[1]} style={{ marginTop: 20 }} className="svg-overlay"/>
              </div> : null}

            </Col>
          })
        }
      </Row>
    </div>
  }
}

export default TripleContentColumns
