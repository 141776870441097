import React from "react"
import Layout from "../../components/Layout"
import useHomeData from "../../static_queries/useHomeData"
import TripleContentColumns from "../../components/TripleContentColumns"

export default function Info() {
  const { frontmatter } = useHomeData()
  return (
    <Layout page="home" topTitle={frontmatter.title} topSubtitle={frontmatter.subtitle}>
      <TripleContentColumns columns={frontmatter.content_columns}/>
    </Layout>
  )
}
