import { graphql, useStaticQuery } from "gatsby"

export default function useHomeData() {
  const data = useStaticQuery(graphql`
    query getHomeData {
      markdownRemark(frontmatter: {url: {eq: "home"}}) {
        id
        frontmatter {
          title
          content_columns {
            images
            list_content
            modal_content
          }
          subtitle
        }
      }
}

  `)
  return data.markdownRemark
}
